import React, { useState, useEffect } from "react";
import '../Estimation/index.css';
import HubexLogo from '../../Assets/Images/logo.png'
import TaskView from "./TaskView";
import axios from "axios";
import swal from "sweetalert";
import { useParams, useNavigate } from 'react-router';
import ESTIMATION_API_ROUTES from "../Services/API";
import ESTIMATION_ROUTES from "../Services/AppRoutes";
import { useToasts } from 'react-toast-notifications'


const EstimationForm = () => {

    const { addToast } = useToasts()
    let navigate = useNavigate();
    let { slug } = useParams();

    const createSlug = (leng) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
        for (let i = 0; i < leng; i++) {
            result += characters.charAt(Math.random() * characters.length)
        }
        return result
    }

    //projects details
    const [projectDetails, setProjectDetails] = useState({
        customer_name: '',
        title: '',
        description: '',
        date: '',
        company_type: '',
        currency_type: '',
        estimation_type: '',
        total: null,
    });

    //Task Details state
    const [taskDetails, setTaskDetails] = useState({
        detail: '',
        time_duration: null,
        evaluate: null
    })

    //Task Array state
    const [taskArray, setTaskArray] = useState([]);
    //TaskId Array state
    const [taskIdArray, setTaskIdArray] = useState([])
    // api data for create estimate
    const createEstimationApiData = {
        customer_name: projectDetails.customer_name,
        title: projectDetails.title,
        description: projectDetails.description,
        date: projectDetails.date,
        currency_type: projectDetails.currency_type,
        estimation_type: projectDetails.estimation_type,
        slug: `estimate-${createSlug(6)}`,
        total: projectDetails.total,
        shareable_link: createSlug(20),
        company_type: projectDetails.company_type,
        tasks: taskIdArray
    }
    // api data for update estimate
    const updateEstimateApiData = {
        id: projectDetails.id,
        customer_name: projectDetails.customer_name,
        title: projectDetails.title,
        description: projectDetails.description,
        date: projectDetails.date,
        currency_type: projectDetails.currency_type,
        estimation_type: projectDetails.estimation_type,
        slug: projectDetails.slug,
        total: projectDetails.total,
        shareable_link: projectDetails.shareable_link,
        company_type: projectDetails.company_type,
        tasks: taskIdArray
    }

    const api_token = localStorage.getItem('access_token');
    (!api_token) && navigate(ESTIMATION_ROUTES.HOME_PAGE)

    useEffect(() => {
        // get estimation
        if (slug) {
            // get estimation api
            let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_GET_API}${slug}`
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
            axios.get(baseURL)
                .then(resp => {
                    const respData = resp.data.data[0]
                    respData.tasks.data.forEach((curr) => {
                        setTaskIdArray((prev) => {
                            return [...prev, curr.id];
                        });
                    });
                    setProjectDetails(respData);
                    setTaskArray(respData.tasks.data);
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                    }
                })
        }
    }, [api_token, navigate, slug])

    // update api handler
    const ProjectApiHandler = async () => {
        if (slug) {
            let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_FORM_API}/${updateEstimateApiData.id}`
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
            await axios.put(baseURL, {
                data: updateEstimateApiData,
            })
                .then(response => {
                    if (response.status === 200) {
                        swal({
                            title: "Thankyou!",
                            text: "Estimation is updated successfully",
                            type: "success"
                        }).then(function () {
                            navigate(`${ESTIMATION_ROUTES.ESTIMATION_LIST}`);
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                    }
                })
        } else {
            let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_FORM_API}`
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
            await axios.post(baseURL, {
                data: createEstimationApiData,
            })
                .then(response => {
                    if (response.status === 200) {
                        swal({
                            title: "Thankyou!",
                            text: "Estimation is created successfully",
                            type: "success"
                        }).then(function () {
                            setProjectDetails({
                                customer_name: '',
                                title: '',
                                description: '',
                                date: '',
                                total: null,
                            });
                            //empty all inputs of task details
                            setTaskArray([]);
                            setTaskIdArray([]);
                        });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                    }
                })
        }
    }

    // Create task API Handler
    const createTaskApiHandler = async () => {
        let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_TASKS}`
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
        await axios.post(baseURL, {
            data: taskDetails
        })
            .then(response => {
                addToast('Task is created Successfully', { appearance: 'success', autoDismiss: true })
                setTaskIdArray(prev => {
                    return [
                        ...prev,
                        response.data.data.id
                    ]
                })

            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    swal("Oops!", "There is something wrong with server!!", "error")
                    navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                }
            })

    }

    // Remove task API Handler
    const removeTaskApiHandler = async (id, ItemIndex) => {
        let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_TASKS}/${id}`
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
        await axios.delete(baseURL)
            .then(() => {
                setTaskArray(taskArray.filter((curr, index) => index !== ItemIndex));
                setTaskIdArray(taskIdArray.filter((curr) => curr !== id));
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    swal("Oops!", "There is something wrong with server!!", "error")
                    navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                }
            })
    }

    //This Function is adding item to task array
    const addItemToTaskHandler = async (event) => {
        event.preventDefault();
        //checks
        if (taskDetails.detail === '' || taskDetails.time_duration === '' || taskDetails.evaluate === 0) {
            addToast('Please fill out the task completely', { appearance: 'warning', autoDismiss: true })

            return;
        }
        await createTaskApiHandler();
        setTaskArray(prevValue => {
            return [
                ...prevValue,
                taskDetails,
            ]
        })

        //empty all inputs of task details
        setTaskDetails({
            detail: '',
            time_duration: '',
            evaluate: '',
        })
    }

    //Following Function is getting and storing the values of the inputs of project details;
    const projectDetailsInputsHandler = (event) => {

        let value;
        if (event.target.name === 'total') {
            value = parseInt(event.target.value);
        }
        else {
            value = event.target.value;
        }
        let name = event.target.name;

        setProjectDetails(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    //Following Function is getting and storing the values of the inputs of project details;
    const taskDetailsInputsHandler = (event) => {
        let value;
        if (event.target.name === 'detail') {
            value = event.target.value;
        } else {
            value = parseInt(event.target.value);
        }
        let name = event.target.name;

        setTaskDetails(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    //The Following function will delete the task when user clicks on delete icon
    const deleteTaskHandler = (ItemIndex, id) => {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this task!",
            icon: "warning",
            buttons: [
                'No',
                'Yes'
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                removeTaskApiHandler(id, ItemIndex)
                addToast('Task is removed Successfully', { appearance: 'success', autoDismiss: true })
            } else {
                swal.close();
                addToast('Task is  not removed', { appearance: 'error', autoDismiss: true })
            }
        })
    }

    //The Following function will delete the task when user clicks on delete icon
    const editTaskHandler = (data, taskIndex) => {
        const updated = taskArray.map((curr, index) => {
            if (index === taskIndex) {
                return {
                    ...curr,
                    detail: data.detail,
                    time_duration: data.time_duration,
                    evaluate: data.evaluate,
                }
            }
            return curr;
        })
        setTaskArray(updated)
    }

    //calculate estimate

    useEffect(() => {
        let totalProjectAmount = taskArray.reduce(function (prev, current) {
            let x = prev + current.evaluate
            return (x)
        }, null);

        setProjectDetails(prevValue => {
            return {
                ...prevValue,
                total: totalProjectAmount
            }
        })

    }, [taskArray]);

    //The Following Function will save the form data and post req uest to server endpoint.
    const formSubmitHandler = async (event) => {
        event.preventDefault();
        if (projectDetails.customer_name === '' || projectDetails.title === '' || projectDetails.date === '' || projectDetails.description === '' || projectDetails.company_type === '' || projectDetails.currency_type === '' || projectDetails.estimation_type === '') {
            swal({ icon: "error", text: "Please fill out the estimation form." })
        } else if (taskArray.length === 0) {
            swal({ icon: "error", text: "Please fill out the tasks." })
        } else {
            await ProjectApiHandler();
            //empty all project details
        }
    }

    const LogoutAPIHandler = () => {
        userLogoutHandler();
        window.location.reload(false);
        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
    }
    //The Following Function will logout the user and destroy the sesion.
    const userLogoutHandler = () => {
        localStorage.removeItem("access_token");
        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
        addToast('Logout successfully', { appearance: 'success', autoDismiss: true })
    }

    const backToListPageHandler = (e) => {
        e.preventDefault();
        navigate(`${ESTIMATION_ROUTES.ESTIMATION_LIST}`);

    }

    window.addEventListener('beforeunload', function (e) {
        // Check if any of the input fields are filled
        if (projectDetails.customer_name !== '' || projectDetails.title !== '' || projectDetails.date !== '' || projectDetails.description !== '' || projectDetails.company_type !== '' || projectDetails.currency_type !== '' || projectDetails.estimation_type !== '') {
            e.preventDefault();
            e.returnValue = '';
        }
    });


    return (
        <>
            <section className="est-section">
                <header>
                    <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm est-header-main w-100">
                        <div className="container">
                        <div className="row d-flex justify-content-between w-100">
                            <div className='col-md-5 p-0 d-flex align-items-center'>
                                <a className="navbar-brand" href="https://hubextech.com"><img src={HubexLogo} alt="hubextech" width="140px" /></a>
                            </div>
                            <div className='col-md-7 d-flex justify-content-end p-0 py-2 py-md-0'>
                                    <button className='btn btn-primary btn-list-main text-light border-0 py-2 mx-1' onClick={backToListPageHandler}>
                                        <i className="fas fa-list-alt me-2"></i>
                                        BACK TO LIST
                                    </button>
                                    <button className='btn btn-dark bg-transparent text-dark py-2 mx-1' onClick={LogoutAPIHandler}>
                                        LOGOUT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="container">
                    <form id="form-input">
                        <div className="row py-4 d-flex justify-content-center">
                            <div className="col-lg-12 p-0">
                                <div className="p-5 bg-white est_form_main rounded est_box_shadow h-100">
                                    <div>
                                        <h5 className="m-0 fw-bold text-secondary">Create Estimation</h5>
                                    </div>
                                    <hr className="mb-lg-5" />
                                    <div className="row mb-3">
                                        <div className="col-xl-4 mb-5 m-lg-0">
                                            <label htmlFor="cutomer-name" className="form-label fw-bold text-secondary">Customer Name</label>
                                            <input className="est_form_input form-control p-2 rounded w-100" id="cutomer-name" type="text" value={projectDetails.customer_name} maxLength="50" name="customer_name" required onChange={projectDetailsInputsHandler} />

                                        </div>
                                        <div className="col-xl-4 mb-5 m-lg-0">
                                            <label htmlFor="project-title" className="form-label fw-bold text-secondary">Project Title</label>
                                            <input className="est_form_input form-control p-2 rounded w-100" type="text" id="project-title" value={projectDetails.title} name="title" maxLength="50" required onChange={projectDetailsInputsHandler} />
                                        </div>
                                        <div className="col-xl-4 mb-5 m-lg-0">
                                            <label htmlFor="estimation-date" className="form-label fw-bold text-secondary">Estimation Date</label>
                                            <input className="est_form_input form-control p-2 rounded w-100 text-secondary" id="estimation-date" type="date" name="date" value={projectDetails.date} required onChange={projectDetailsInputsHandler} />
                                        </div>
                                        <div className="col-12 my-3">
                                            <label htmlFor="project-description" className="form-label fw-bold mt-3 text-secondary">Project Description</label>
                                            <textarea className="est_form_input form-control est_textArea p-2 rounded w-100 h-100" value={projectDetails.description} maxLength="500" name="description" id="project-description" required onChange={projectDetailsInputsHandler} />
                                        </div>
                                    </div>


                                    <div className="row mt-5 mb-3">
                                        <div className="col-xl-4 my-3">
                                            <label htmlFor="company-type" className="form-label fw-bold text-secondary">Select Company Type</label>
                                            <select id="company-type" className="w-100 form-control p-2 border-1 bg-transparent est_form_input text-secondary rounded" value={projectDetails.company_type} name="company_type" required onChange={projectDetailsInputsHandler}>
                                                <option value="" disabled="disabled">Select Company type</option>
                                                <option value="SouthvilleSolutions">Southville Solutions</option>
                                                <option value="HubexTech">HubexTech</option>
                                            </select>
                                        </div>

                                        <div className="col-xl-4 my-3">
                                            <label htmlFor="currency-type" className="form-label fw-bold text-secondary">Select Currency Type</label>
                                            <select id="currency-type" className="w-100 form-control p-2 border-1 bg-transparent est_form_input text-secondary rounded" value={projectDetails.currency_type} name="currency_type" required onChange={projectDetailsInputsHandler}>
                                                <option value="" disabled="disabled">Select Currency type</option>
                                                <option value="USD">USD</option>
                                                <option value="PKR">PKR</option>
                                                <option value="CAD">CAD</option>
                                                <option value="GBP">GBP</option>
                                            </select>
                                        </div>
                                        <div className="col-xl-4 my-3">
                                            <label htmlFor="estimation-type" className="form-label fw-bold text-secondary">Select Estimation Type</label>
                                            <select id="estimation-type" className="mb-3 mb-lg-0 bg-transparent est_form_input text-secondary rounded p-2 w-100 form-control" value={projectDetails.estimation_type} name="estimation_type" required onChange={projectDetailsInputsHandler}>
                                                <option value="" disabled="disabled">Select Estimation Type</option>
                                                <option value="hourly">In hours</option>
                                                <option value="daily">In days</option>
                                            </select>
                                        </div>
                                    </div>


                                    {/* Task View -------start */}
                                    <div className={(taskArray.length > 0) ? `est_taskView p-3 mt-3 mb-4` : `d-none`} >
                                        <div className="d-none d-lg-block">
                                            <div className="row m-0 px-3">
                                                <div className="col-md-6 p-0">
                                                    <span className="w-100 form-label fw-bold text-secondary"> TASK DETAILS</span>
                                                </div>
                                                <div className="col-md-2 p-0 text-center">
                                                    <span className="w-100 form-label fw-bold text-secondary"> DURATION</span>
                                                </div>
                                                <div className="col-md-2 p-0 text-center">
                                                    <span className="w-100 form-label fw-bold text-secondary"> COST</span>
                                                </div>
                                                <div className="col-md-2 p-0 text-center">
                                                    <span className="w-100 form-label fw-bold text-secondary"> ACTIONS</span>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <ul className="est_taskViewList list-unstyled task-main text-area">
                                            {
                                                taskArray.map((curr, index) => {
                                                    return <TaskView key={index} editTaskID={curr.id} Task={curr} Delete={deleteTaskHandler} tasksId={taskIdArray} Edit={editTaskHandler} />
                                                })
                                            }
                                        </ul>
                                    </div>
                                    {/* Task View -------end */}

                                    <div className="d-none d-lg-block">
                                        <div className="row m-0">
                                            <div className="col-md-5 p-0 me-2">
                                                <span className="form-label fw-bold text-secondary"> TASK DETAILS</span>
                                            </div>
                                            <div className="col-md-3 p-0 me-2">
                                                <span className="form-label fw-bold text-secondary"> DURATION</span>
                                            </div>
                                            <div className="col-md-3 p-0 me-2">
                                                <span className="form-label fw-bold text-secondary"> COST</span>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>


                                    <div className="d-flex flex-column mb-0 mb-lg-4">
                                        <div className="row m-0">
                                            <div className="col-lg-5 p-0 me-2">
                                                <label className="mb-2 d-lg-none">Task Details</label>
                                                <textarea className="est_form_input w-100 est_textArea p-2 rounded mb-3" value={taskDetails.detail} type="text" name="detail" required onChange={taskDetailsInputsHandler} />
                                            </div>
                                            <div className="col-lg-2 p-0 me-2">
                                                <div className="d-flex flex-column">
                                                    <label className="mb-2 d-lg-none">Duration</label>
                                                    <input type="number" name="time_duration" className="est_form_input w-100 p-2 rounded mb-3" value={taskDetails.time_duration} required onChange={taskDetailsInputsHandler} />
                                                </div>
                                            </div>
                                            <div className="col-lg-2 p-0 me-2">
                                                <label className="mb-2 d-lg-none">Cost</label>
                                                <input type="number" name="evaluate" className="est_form_input w-100 p-2 rounded mb-3" min="0" max="10" step="0.25" value={taskDetails.evaluate} onChange={taskDetailsInputsHandler} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* New Line Button */}
                                    <div>
                                        <button className="btn btn-secondary est_button est_newLine-btn" onClick={addItemToTaskHandler}>
                                            <span>Add Task</span>
                                            <i className="fas fa-plus ms-2"></i>
                                        </button>
                                    </div>

                                    {/* Cost Total Area--------start */}
                                    <div className="row mt-5 m-lg-0">
                                        <div className="col-lg-8 offset-lg-4">
                                            <ul className="p-0 list-unstyled">
                                                <li>
                                                    <div className="border border-2 border-secondary d-flex justify-content-between align-items-center">
                                                        <div className="py-3 px-2 main-bg-light text-center fw-bold w-25">
                                                            <span>Total</span>
                                                        </div>
                                                        {
                                                            projectDetails.currency_type !== 'PKR' ?
                                                                <h5 className='fw-bold me-2'>{projectDetails.total} $</h5>
                                                                :
                                                                <h5 className='fw-bold me-2'>{projectDetails.total} PKR</h5>
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="offset d-flex justify-content-end p-0 my-4">
                                            <button className="btn btn-secondary est_button border-0 mx-1" onClick={formSubmitHandler}>
                                                <span>Save</span>
                                                <i className="fa fa-check-circle ms-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <footer>
                    <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm est-footer-main w-100">
                        <div className="container d-flex align-items-center justify-content-center w-100">
                            <h6 className="my-3 fw-bold">
                                2022 &copy;
                                <a href="https://www.hubextech.com/" className="mx-2 copyright-main">
                                    HubexTech
                                </a>
                                - All Rights Reserved.
                            </h6>
                        </div>
                    </nav>
                </footer>
            </section>
        </>
    );
}

export default EstimationForm;