import React, { useState } from "react";
import '../EstimationUI/EstimationUI.css'
import axios from "axios";
import swal from "sweetalert";
import ESTIMATION_API_ROUTES from "../Services/API";
import { useToasts } from 'react-toast-notifications'

const TaskView = (props) => {

    // getting data with props from estimation
    const taskData = props.Task;
    const { addToast } = useToasts()

    const [show, setShow] = useState({
        state: false,
        index: null,
        id: ''
    })

    const [editInputs, setEditInputs] = useState({
        detail: taskData.detail,
        time_duration: taskData.time_duration,
        pay_rate: taskData.pay_rate,
        evaluate: taskData.evaluate,
    })

    const editInputChangeHandler = (event) => {
        let value;
        if (event.target.name === 'detail') {
            value = event.target.value;
        } else {
            value = parseInt(event.target.value);
        }
        let name = event.target.name;

        setEditInputs(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })

    }
    const api_token = localStorage.getItem('access_token')

    const getCurrentIndex = (event) => {
        const list = document.querySelector('.est_taskViewList');
        const current = event.target.parentElement.parentElement.parentElement;
        const index = [...list.children].indexOf(current);
        return index;
    }

    const taskDeleteHandler = (event) => {
        event.preventDefault();
        const index = getCurrentIndex(event)
        const currId = props.tasksId[index];
        props.Delete(index, currId);
    }
    const taskEditHandler = (event) => {
        event.preventDefault();
        const index = getCurrentIndex(event)
        const currId = props.tasksId[index];

        setShow({
            state: true,
            index: index,
            id: currId
        })
    }

    const editedDataHandler = (data, index) => {
        props.Edit(data, index)
    }

    const onEditCloseHandler = () => {
        setShow(prev => {
            return {
                ...prev,
                state: false
            }
        })
    }

    const editCancelHandler = (event) => {
        event.preventDefault();
        onEditCloseHandler();
    }

    // Update task API Handler
    const updateTaskApiHandler = async () => {
        let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_TASKS}/${show.id}`
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
        await axios.put(baseURL, {
            data: editInputs
        })
            .then(response => {
                if (response.status === 200) {
                    addToast('Task is updated Successfully', { appearance: 'success', autoDismiss: true })
                }
            })
            .catch(error => {
                addToast('Task is not updated', { appearance: 'error', autoDismiss: true })
                if (error.response.status === 401 || error.response.status === 403) {
                    swal("Oops!", "There is something wrong with server!!", "error")
                }
            })
    }

    const taskUpdateHandler = (event) => {
        event.preventDefault();
        //checks
        if (editInputs.detail === '' || editInputs.time_duration === '' || editInputs.evaluate === 0) {
            addToast('Please fill out the task completely', { appearance: 'warning', autoDismiss: true })
            return;
        }
        editedDataHandler(editInputs, show.index);
        onEditCloseHandler()
        updateTaskApiHandler()
    }

    return (
        <li>
            <div className={`taskView-item row m-0 bg-white py-3 mb-2 rounded-3 task-ui text-area ${(show.state) ? `taskEdit-view` : `d-none`}`}>
                <div className="row m-0 w-100 align-items-end">
                    <div className="col-xl-6">
                        <label className="d-xl-none form-label fw-bold text-secondary mb-2">TASK DETAILS</label>
                        <input className="est_form_input w-100 p-2 rounded mb-3" type="text" name="detail" value={editInputs.detail} required onChange={editInputChangeHandler} />
                    </div>
                    <div className="col-xl-2">
                        <label className="d-xl-none form-label fw-bold text-secondary mb-2">DURATION</label>
                        <div className="d-flex flex-column">
                            <input type="number" name="time_duration" className="est_form_input w-100 p-2 rounded mb-3" value={editInputs.time_duration} required onChange={editInputChangeHandler} />
                        </div>
                    </div>
                    <div className="col-xl-2">
                        <label className="d-xl-none form-label fw-bold text-secondary mb-2">COST</label>
                        <input type="number" name="evaluate" className="est_form_input w-100 p-2 rounded mb-3" value={editInputs.evaluate} required onChange={editInputChangeHandler} />
                    </div>
                    <div className="col-md-2 d-flex align-items-start justify-content-between p-0">
                        <button className="btn btn-danger taskView_icons mb-3" onClick={editCancelHandler}>Cancel</button>
                        <button className="btn btn-secondary bg-transparent taskView_icons text-dark mb-3" onClick={taskUpdateHandler}>Update</button>
                    </div>
                </div>
            </div>
            <div className={`taskView-item row m-0 bg-white px-2 py-3 mb-2 rounded-3 task-ui text-area ${(show.state) ? `d-none` : `taskEdit-view`}`}>
                <div className="col-md-6 d-flex flex-column align-items-md-center flex-md-row p-2 text-break">
                    <span className="fw-bold fs-5 d-md-none">TASK DETAILS</span>
                    <span className="w-100">{taskData.detail}</span>
                </div>
                <div className="col-md-2 d-flex flex-column align-items-md-center flex-md-row p-2 text-break text-md-center">
                    <span className="fw-bold fs-5 d-md-none">DURATION</span>
                    <span className="w-100">{taskData.time_duration}</span>
                </div>
                <div className="col-md-2 d-flex flex-column align-items-md-center flex-md-row p-2 text-break text-md-center">
                    <span className="fw-bold fs-5 d-md-none">COST</span>
                    <span className="w-100">{taskData.evaluate}</span>
                </div>
                <div className="col-md-2 d-flex align-items-center justify-content-center p-0">
                    <button className="btn btn-secondary bg-transparent taskView_icons text-dark me-3" onClick={taskEditHandler}>Edit</button>
                    <button className="btn btn-danger text-light taskView_icons" onClick={taskDeleteHandler}>Remove</button>
                </div>



            </div>
        </li>

    );
}

export default TaskView;