import React, { useState } from 'react';
import './EstimationLogin.css';
import { Container } from 'react-bootstrap';
import logo from '../../Assets/Images/logo.png';
import axios from "axios";
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
import ESTIMATION_API_ROUTES from "../Services/API";
import ESTIMATION_ROUTES from "../Services/AppRoutes";
import { useToasts } from 'react-toast-notifications'

const Login = () => {

    const { addToast } = useToasts()
    let navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        login_email: '',
        login_password: '',
    });

    const [formErrors, setFormErrors] = useState({});
    const [loginStatus, setLoginStatus] = useState('d-none');
    const [disabled, setDisabled] = useState(false);

    const loginInputHandler = (e) => {
        const Name = e.target.name;
        const keyValue = e.target.value;
        setLoginData((prev) => {
            return {
                ...prev,
                [Name]: keyValue
            }
        })
    }

    const loginDetailsHandler = (e) => {
        e.preventDefault();
        apiLoginDataPostingHandler();
        setDisabled(true)
        setFormErrors(validate(loginData));
        setLoginData({
            login_email: '',
            login_password: '',
        })
    }

    const validate = (values) => {
        const errors = {};
        if (!values.login_email) {
            errors.login_email = "Email is required!"
            setLoginStatus('d-none')
        }
        if (!values.login_password) {
            errors.login_password = "Password is required!"
            setLoginStatus('d-none')
        }
        return errors;
    }

    const apiLoginDataPostingHandler = async () => {
        const baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.LOGIN_API}`;
        const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        const result = regex.test(loginData.login_email);
        if (result) {
            await axios.post(baseURL, {
                identifier: loginData.login_email,
                password: loginData.login_password
            })
                .then(response => {
                    if (response.status === 200) {
                        localStorage.setItem('access_token', response.data.jwt);
                        localStorage.setItem('user', JSON.stringify(response.data.user));
                        navigate(`${ESTIMATION_ROUTES.ESTIMATION_LIST}`);
                        addToast('Login Successfully', { appearance: 'success', autoDismiss: true })
                    }
                })
                .catch(error => {
                    if (error.response.status !== 200) {
                        setLoginStatus('d-block')
                    }
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                    }
                })
        }
    }

    return (
        <section className="est-section-main">
            <Container>
                <div className='d-flex justify-content-center align-items-center login-card-main'>
                    <div className="screen">
                        <div className='p-3'>
                            <img src={logo} width="35%" alt="" />
                        </div>
                        <div className="screen__content">
                            <div className='pt-4 pb-2 px-3'>
                                <span className='login-head fw-bold'>
                                    Get Started
                                </span>
                            </div>
                            <form className="login" name="login_form">
                                <div className="login__field">
                                    <i className="login__icon fas fa-user"></i>
                                    <input type="email" className="login__input validate" placeholder="Enter the email" name='login_email' onChange={loginInputHandler} />
                                    <p className='px-2 text-danger m-0'>{formErrors.login_email}</p>
                                </div>
                                <div className="login__field">
                                    <i className="login__icon fas fa-lock"></i>
                                    <input type="password" className="validates login__input" placeholder="Enter the password" name='login_password' onChange={loginInputHandler} />
                                    <p className='px-2 text-danger m-0'>{formErrors.login_password}</p>
                                </div>
                                <div id="error_first_name" class={`${loginStatus} text-danger`}>
                                    Please enter a valid email and password!!
                                </div>
                                <div>
                                    <button className="login__submit w-50 mt-3" onClick={loginDetailsHandler} disabled={disabled}>
                                        <div className='d-flex justify-content-between'>
                                            <span>LOGIN</span>
                                            <span className='d-flex align-items-center'>
                                                <i className="button__icon fas fa-chevron-right"></i>
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Login;