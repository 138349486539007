import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import swal from "sweetalert";
import '../Estimation/index.css';
import './EstimationUI.css';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import ESTIMATION_API_ROUTES from "../Services/API";
import ESTIMATION_ROUTES from "../Services/AppRoutes";
import HubexLogo from '../../Assets/Images/logo.png'
import SVLogo from '../../Assets/Images/logosv.png'
import Avatar from 'react-avatar';
import html2pdf from 'html2pdf.js'

const EstimationUI = () => {

    let navigate = useNavigate();
    let { slug } = useParams();

    const [projectDetails, setProjectDetails] = useState({});
    const [userCommmentInput, setUserCommentInput] = useState({
        name: '',
        body: '',
    })
    const [displayComment, setDisplayComment] = useState('d-none');
    const [comment, setComment] = useState([]);
    const [loading, setLoading] = useState(false)
    const [showLoader, setShowLoader] = useState('d-block');
    let api_token;
    if (localStorage.getItem('access_token')) {
        api_token = localStorage.getItem('access_token');
    } else {
        api_token = process.env.REACT_APP_AUTH_KEY;
    }
    (!api_token) && navigate(ESTIMATION_ROUTES.HOME_PAGE)

    useEffect(() => {
        // fetch estimate
        if (slug) {
            let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_GET_API}${slug}`
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
            axios.get(baseURL)
                .then(resp => {
                    setProjectDetails(resp.data.data[0])
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                    }
                })
        }
        // getting userName from which we'd set locally
        var userName = localStorage.getItem('userName')
        if (userName) {
            setUserCommentInput({
                name: userName,
                body: '',
            })
        }
    }, [api_token, slug, navigate])

    // get comment API
    const getCommentApiHandler = async (taskID) => {
        let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.GET_COMMENTS_API}${taskID}`
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
        await axios.get(baseURL)
            .then(res => {
                if (res.status === 200) {
                    setComment(res.data.data)
                    setLoading(false)
                    setShowLoader('d-block')
                }
            })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    swal("Oops!", "There is something wrong with server!!", "error")
                    navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                }
            })
    }

    // add comment API
    const addCommentApiHandler = async (taskID) => {
        const baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ADD_COMMENTS_API}`;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
        await axios.post(baseURL, {
            data: {
                task: taskID,
                name: userCommmentInput.name,
                body: userCommmentInput.body,
            }

        })
            .catch(error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    swal("Oops!", "There is something wrong with server!!", "error")
                    navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                }
            })
    }
    // input change Handler
    const handleInputChange = e => {
        const value = e.target.value;
        const key = e.target.name;

        setUserCommentInput({
            ...userCommmentInput,
            [key]: value
        });
        localStorage.setItem('userName', userCommmentInput.name)
    }
    // Comment Handler
    const commentPostingHandler = (e, taskID) => {
        e.preventDefault();
        setComment(prev => {
            return [
                ...prev,
                userCommmentInput
            ]
        });
        addCommentApiHandler(taskID);
        setDisplayComment('d-block')
        setUserCommentInput({
            name: localStorage.getItem('userName'),
            body: '',
        })
    }
    const getTaskIdHandler = (taskID) => {
        setComment([])
        getCommentApiHandler(taskID)
        setLoading(true)
        setShowLoader('d-none')
        collapseHandler()
    }
    // PDF Generator
    var element = document.getElementById('pdf');
    var opt = {
        margin: 1,
        filename: 'estimation.pdf',
        image: { type: 'png', quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
    };

    const handlePdfGenerator = () => {
        html2pdf().from(element).set(opt).save();
    }

    const collapseHandler = () => {
        let elements = document.getElementsByClassName("collapse");
        for (let i = 0, len = elements.length; i < len; i++) {
            elements[i].classList.remove('show')
        }
    }
    return (
        <>
            <section className='bg-light py-5 px-3'>
                <div className='card border-0'>
                    <Container>
                        <div id='pdf'>
                            <div className="row est-ui-main my-5">
                                <div className='col-12 col-md-6 d-flex justify-content-start align-items-md-center'>
                                    <h5 className='fw-bold me-2'>Estimation ID:</h5>
                                    <h5 className='est-head text-uppercase'>{`${'#'}${projectDetails.slug}`}</h5>
                                </div>
                                <div className='col-12 col-md-6 d-flex justify-content-md-end justify-content-center'>
                                    {
                                        projectDetails.company_type === 'HubexTech' &&
                                        <a className="navbar-brand" href="https://hubextech.com/"><img src={HubexLogo} alt="hubextech" width="200px" /></a>
                                    }
                                    {
                                        projectDetails.company_type === 'SouthvilleSolutions' &&
                                        <a className="navbar-brand" href="https://southvillesol.com/"><img src={SVLogo} alt="southville solutions" width="200px" /></a>
                                    }

                                </div>
                            </div>
                            <hr />
                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div>
                                        <h5 className='fw-bold'>Project Description:</h5>
                                        <h6 className='est-head'>{projectDetails.description}</h6>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 d-flex justify-content-end'>
                                    <div className='text-end'>

                                        {
                                            projectDetails.company_type === 'HubexTech' &&
                                            <div>
                                                <h5 className='fw-bold text-capitalize'>
                                                    HubexTech
                                                </h5>
                                                <h6 className='est-head'>
                                                    4 Riverina boulevard, Brookfield 3338,
                                                    <br />
                                                    Melbourne, Australia.
                                                    <br />
                                                    sales@hubextech.com
                                                    <br />
                                                    +61 426 508 849
                                                </h6>
                                            </div>

                                        }
                                        {
                                            projectDetails.company_type === 'SouthvilleSolutions' &&
                                            <div>
                                                <h5 className='fw-bold text-capitalize'>
                                                    Southville Solutions
                                                </h5>
                                                <h6 className='est-head'>
                                                    85 Service Rd, Block R1, Phase 2, Johar Town,
                                                    <br />
                                                    Lahore, Punjab, Pakistan.
                                                    <br />
                                                    hr@southvillesol.com
                                                    <br />
                                                    +92 320 402 7604
                                                </h6>
                                            </div>

                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='card my-4 p-3 bg-white rounded est_box_shadow'>
                                <div className='d-flex row'>
                                    <div className='col-12 col-md-6 text-uppercase'>
                                        <div className='d-flex'>
                                            <h6 className='est-head'>
                                                Date:
                                            </h6>
                                            <h6 className='fw-bold ms-2'>
                                                {projectDetails.date}
                                            </h6>
                                        </div>
                                        <div className='d-flex'>
                                            <h6 className='est-head'>
                                                client Name:
                                            </h6>
                                            <h6 className='fw-bold ms-2'>
                                                {projectDetails.customer_name}
                                            </h6>
                                        </div>
                                        <div className='d-flex'>
                                            <h6 className='est-head'>
                                                Project Title:
                                            </h6>
                                            <h6 className='fw-bold ms-2'>
                                                {projectDetails.title}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6 text-uppercase'>
                                        <div className='d-flex'>
                                            <h6 className='est-head'>
                                                Estimation Type:
                                            </h6>
                                            <h6 className='fw-bold ms-2'>
                                                {projectDetails.estimation_type}
                                            </h6>
                                        </div>
                                        <div className='d-flex'>
                                            <h6 className='est-head'>
                                                currency type:
                                            </h6>
                                            <h6 className='fw-bold ms-2'>
                                                {projectDetails.currency_type}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card bg-white rounded est_box_shadow h-100'>
                                <div className={`${projectDetails.company_type === `HubexTech` ? `mdb-dataTable-head` :  `estimationUi-head`} card-header border-0 fs-6`}>
                                    <div className='row m-0 p-2 text-center fw-bold w-100'>
                                        <div className='p-0 col-4 text-start'>TASK</div>
                                        <div className='p-0 col-3'>TIME DURATION </div>
                                        <div className='p-0 col-2'>COST</div>
                                        <div className='p-0 col-3'>COMMENTS</div>
                                    </div>
                                </div>
                                <div className='card-body fs-6'>
                                    {
                                        projectDetails.tasks &&
                                        projectDetails.tasks.data.map((task) => {
                                            return (
                                                <div key={task.id} className='row m-0 est-head p-2 text-center align-items-center'>
                                                    <div className='p-0 col-4 text-start'>{task.detail}</div>
                                                    <div className='p-0 col-3'>{task.time_duration}</div>
                                                    <div className='p-0 col-2'>{task.evaluate}</div>
                                                    <div className='p-0 col-3'>
                                                        <div>
                                                            <button
                                                                className='btn btn-secondary comment-btn collapse-btn'
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#comments-${task.id}`}
                                                                aria-expanded="false"
                                                                aria-controls="comments"
                                                                tooltip="Add comments to get a better understanding of this invoice"
                                                                flow="left"
                                                                onClick={() => getTaskIdHandler(task.id)}>
                                                                <div className={`${showLoader}`}>
                                                                    <i className="fas fa-comment-alt icon-color"></i>
                                                                    <span className="badge badge-ui">{task !== null ? task.commentsCount : 0}</span>
                                                                </div>
                                                                {
                                                                    loading &&
                                                                    <div>
                                                                        <i class="fa fa-spinner fa-spin"></i>
                                                                    </div>
                                                                }
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="collapse" id={`comments-${task.id}`}>
                                                        <div className='card border-0 comnt-section d-flex justify-content-center m-5'>
                                                            <div className='justify-content-center m-3'>
                                                                <div className='form-floating my-2 overflow-auto cmnt-ui'>
                                                                    {
                                                                        comment && (
                                                                            comment.map((curr, index) => {
                                                                                return (
                                                                                    <div key={index}>
                                                                                        <div className='d-flex mt-2'>
                                                                                            <Avatar name={curr.name} maxInitials={2} />
                                                                                            <span className='fw-bolder d-flex align-items-center ms-2'>
                                                                                                {curr.name}
                                                                                            </span>
                                                                                        </div>
                                                                                        <p className='text-start px-5'>
                                                                                            {curr.body}
                                                                                        </p>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        )
                                                                    }
                                                                    <div className={`${displayComment}`}>
                                                                        {
                                                                            comment.name ?
                                                                                <div className='d-flex mt-2'>
                                                                                    <Avatar name={comment.name} maxInitials={2} />
                                                                                    <span className='fw-bolder d-flex align-items-center ms-2'>
                                                                                        {comment.name}
                                                                                    </span>
                                                                                </div>
                                                                                : null
                                                                        }
                                                                        <p className='text-start px-5'>
                                                                            {comment.body}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className='card comnt-main w-100'>
                                                                    <div className='card-header bg-white border-0 d-flex'>
                                                                        <Avatar name={userCommmentInput.name} maxInitials={2} />
                                                                        <div className='d-flex align-items-center ms-2'>
                                                                            <input
                                                                                type="text"
                                                                                name='name'
                                                                                className='text-area fw-bold'
                                                                                placeholder='Enter your name'
                                                                                value={userCommmentInput.name}
                                                                                onChange={handleInputChange}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='card-body'>
                                                                        <textarea
                                                                            type="text"
                                                                            id='commentSection'
                                                                            rows="4"
                                                                            name='body'
                                                                            className='cmnt_input text-area'
                                                                            placeholder='Write comment here'
                                                                            value={userCommmentInput.body}
                                                                            onChange={handleInputChange}
                                                                        />
                                                                        <div className='d-flex justify-content-end'>
                                                                            <button
                                                                                id='submitComment'
                                                                                className='btn btn-success'
                                                                                onClick={(event) => commentPostingHandler(event, task.id)}
                                                                            >
                                                                                Comment
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='d-flex py-3 justify-content-end me-3 text-uppercase'>
                                <h5 className='est-head me-2'>
                                    Total Amount:
                                </h5>
                                {
                                    projectDetails.currency_type !== 'PKR' ?
                                        <h5 className='fw-bold me-2'>{projectDetails.total} $</h5>
                                        :
                                        <h5 className='fw-bold me-2'>{projectDetails.total} PKR</h5>
                                }
                            </div>
                        </div>
                    </Container>
                </div>
                <div className='w-100 d-flex justify-content-center py-3'>
                    <button className='btn btn-secondary' onClick={handlePdfGenerator}> <i className='fas fa-download me-2'></i>Download</button>
                </div>
            </section>
        </>
    )
}
export default EstimationUI;