// estimation API route as constant
const ESTIMATION_API_ROUTES = {
    // estimation POST API route as constant
    ESTIMATION_FORM_LIST_API: '/api/estimates?populate=*',
    // estimation POST API route as constant
    ESTIMATION_FORM_API: '/api/estimates',
    // Add comments API route as constant
    ADD_COMMENTS_API: '/api/comments',
    // GET comments API route as constant
    GET_COMMENTS_API: '/api/comments?populate=*&filters[task]=',
    // Login User API route as constant
    LOGIN_API: '/api/auth/local',
    // Shareable Link API route as constant
    ESTIMATION_GET_API: '/api/estimates?[populate]=*&filters[slug]=',
    // Logout API route as constant
    LOGOUT_API: '/api/admin_users',
    // Estimation Tasks Post API route as constant
    ESTIMATION_TASKS: '/api/tasks',
}
export default ESTIMATION_API_ROUTES