import React, { useState, useEffect } from 'react';
import '../Estimation/index.css';
import HubexLogo from '../../Assets/Images/logo.png'
import { useNavigate } from 'react-router';
import ESTIMATION_ROUTES from "../Services/AppRoutes";
import { MDBDataTableV5 } from 'mdbreact';
import axios from "axios";
import ESTIMATION_API_ROUTES from "../Services/API";
import { Link } from "react-router-dom";
import * as qs from 'qs'
import swal from "sweetalert";
import { useToasts } from 'react-toast-notifications'

const EstimationList = () => {

    let navigate = useNavigate();
    const { addToast } = useToasts()

    const [listItems, setListItems] = useState([])
    const [datatable, setDatatable] = useState([])
    const data = {
        columns: [

            {
                label: 'Id#',
                field: 'id',
                sort: 'disabled',


            },
            {
                label: 'Customer Name',
                field: 'customer_name',
                sort: 'disabled',


            },
            {
                label: 'Project Title',
                field: 'title',
                sort: 'disabled',


            },
            {
                label: 'Estimation Date',
                field: 'date',
                sort: 'disabled',

            },
            {
                label: 'Actions',
                field: 'actions',
                sort: 'disabled',

            },
        ],
        rows: listItems,
    };
    const mobileFormdata = {
        columns: [

            {
                label: 'Id#',
                field: 'id',
                sort: 'disabled',


            },
            {
                label: 'Project Title',
                field: 'title',
                sort: 'disabled',


            },
            {
                label: 'Actions',
                field: 'actions',
                sort: 'disabled',

            },
        ],
        rows: listItems,
    };

    const api_token = localStorage.getItem('access_token');
    (!api_token) && navigate(ESTIMATION_ROUTES.HOME_PAGE)

    const LogoutAPIHandler = () => {
        userLogoutHandler();
        window.location.reload(false);
        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);

    }
    //The Following Function will logout the user and destroy the sesion.
    const userLogoutHandler = () => {
        localStorage.clear();
        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
        addToast('Logout Successfully', { appearance: 'success', autoDismiss: true })
    }

    const createBtnHandler = (e) => {
        e.preventDefault();
        navigate(`${ESTIMATION_ROUTES.ESTIMATION_FORM}`);
    }

    useEffect(() => {
        // get estimation api
        const query = qs.stringify({
            sort: ['id:desc'],
            pagination: {
                page: 1,
                pageSize: 100,
            }
        })
        const fecthingData = () => {
            let baseURL = `${process.env.REACT_APP_BASE_URL}${ESTIMATION_API_ROUTES.ESTIMATION_FORM_LIST_API}&${query}`
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + api_token;
            axios.get(baseURL)
                .then(resp => {
                    setDatatable(resp.data.data)
                })
                .catch(error => {
                    if (error.response.status === 401 || error.response.status === 403) {
                        swal("Oops!", "There is something wrong with server!!", "error")
                        navigate(`${ESTIMATION_ROUTES.HOME_PAGE}`);
                    }
                })
        }
        fecthingData();
    }, [api_token, navigate]);

    useEffect(() => {
        let estimates = [];
        datatable.forEach((item) => {
            item.id = (
                <div className='badge primary btn-list-main badge-pill p-1 px-3'>{item.id}</div>
            )
            item.actions = (
                <div className="d-flex justify-content-center">
                    <Link to={`${ESTIMATION_ROUTES.ESTIMATION_EDIT.replace(':slug', item.slug)}`}>
                        <span className="btn btn-secondary bg-transparent list-main text-dark me-3">Edit</span>
                    </Link>
                    <Link to={`${ESTIMATION_ROUTES.ESTIMATION_UI.replace(':slug', item.slug)}`} target="_blank">
                        <span className="btn btn-list-main list-main text-light border-0">Preview</span>
                    </Link>
                </div>
            );
            estimates.push(item);
        });
        setListItems(estimates);
    }, [datatable]);

    return (
        <section className="est-section">
            <header>
                <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm  est-header-main w-100">
                <div className="container">
                        <div className="row d-flex justify-content-between w-100">
                            <div className='col-md-5 p-0 d-flex align-items-center'>
                                <a className="navbar-brand" href="https://hubextech.com"><img src={HubexLogo} alt="hubextech" width="140px" /></a>
                            </div>
                            <div className='col-md-7 d-flex justify-content-end p-0 py-2 py-md-0'>
                                <button className='btn btn-primary btn-list-main text-light border-0 py-2 mx-1' onClick={createBtnHandler}>
                                    <i className="fas fa-plus-square me-2"></i>
                                    CREATE ESTIMATE
                                </button>
                                <button className='btn btn-dark bg-transparent text-dark py-2 mx-1' onClick={LogoutAPIHandler}>
                                    LOGOUT
                                </button>
                            </div>

                        </div>
                    </div>
                </nav>
            </header>
            {/* Mobile Responsive form */}
            <div className="container d-md-none d-block">
                <form id="form-input">
                    <div className="row py-5 d-flex justify-content-center est-form-main">
                        <div className="bg-white est_form_main rounded est_box_shadow h-100">
                            <div>
                                <h5 className="m-0 fw-bold text-secondary">Project Estimation List</h5>
                            </div>
                            <hr className="mb-lg-3" />
                            {/* with simple MDB table */}
                            <MDBDataTableV5
                                hover
                                data={mobileFormdata}
                                entriesOptions={[10, 20, 50]}
                                entries={10}
                                pagingbottom="true"
                                searchTop
                                searchBottom={false}
                                barReverse
                            />
                        </div>
                    </div>
                </form>
            </div>
            {/* Laptop Responsive form */}
            <div className="container d-md-block d-none">
                <form id="form-input">
                    <div className="row py-4 d-flex justify-content-center est-form-main">
                        <div className="p-5 bg-white est_form_main rounded est_box_shadow h-100">
                            <div>
                                <h5 className="m-0 fw-bold text-secondary">Project Estimation List</h5>
                            </div>
                            <hr className="mb-lg-3" />
                            {/* with simple MDB table */}
                            <MDBDataTableV5
                                hover
                                data={data}
                                entriesOptions={[10, 20, 50]}
                                entries={10}
                                pagingbottom="true"
                                searchTop
                                searchBottom={false}
                                barReverse
                            />
                        </div>
                    </div>
                </form>
            </div>
            <footer>
                <nav className="est_navbar navbar navbar-expand-lg navbar-light bg-white shadow-sm est-footer-main w-100">
                    <div className="container d-flex align-items-center justify-content-center w-100">
                        <h6 className="my-3 fw-bold">
                            2022 &copy;
                            <a href="https://www.hubextech.com/" className="mx-2 copyright-main">
                                HubexTech
                            </a>
                            - All Rights Reserved.
                        </h6>
                    </div>
                </nav>
            </footer>
        </section>
    )
}

export default EstimationList