import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/js/brands'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/fontawesome'
import EstimationForm from './Components/EstimationForm/EstimationForm';
import EstimationLogin from './Components/EstimationLogin/EstimationLogin'
import EstimationUI from './Components/EstimationUI/EstimationUI'
import ESTIMATION_ROUTES from "./Components/Services/AppRoutes"
import EstimationList from './Components/EstimationList/EstimationList';
import { ToastProvider } from 'react-toast-notifications'

function App() {

    return (
        <ToastProvider>
            <Routes>
                <Route exact path={`${ESTIMATION_ROUTES.ESTIMATION_UI}`} element={<EstimationUI />} />
                <Route exact path={`${ESTIMATION_ROUTES.HOME_PAGE}`} element={<EstimationLogin />}></Route>
                <Route exact path={`${ESTIMATION_ROUTES.ESTIMATION_FORM}`} element={<EstimationForm />}></Route>
                <Route exact path={`${ESTIMATION_ROUTES.ESTIMATION_EDIT}`} element={<EstimationForm />}></Route>
                <Route exact path={`${ESTIMATION_ROUTES.ESTIMATION_LIST}`} element={<EstimationList />}></Route>
            </Routes>
        </ToastProvider>
    );
}
export default App;