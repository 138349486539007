// estimation route as constant
const ESTIMATION_ROUTES = {
    // estimation UI route as constant
    ESTIMATION_UI: '/estimation/:slug',
    // estimation Form Edit route as constant
    ESTIMATION_EDIT: '/estimate/:slug',
    // estimation Form route as constant
    ESTIMATION_FORM: '/estimate',
    // estimation HomePage route as constant
    HOME_PAGE: '/',
    // estimation List route as constant
    ESTIMATION_LIST: '/estimationlist'
}
export default ESTIMATION_ROUTES